var __jsx = React.createElement;
import { VStack } from '@chakra-ui/react';
import { ProBadgeIcon, Text } from '@playful/design_system';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';
export var GetHatchProCTA = function GetHatchProCTA() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    onClose = _useProjectSettingsCo.onClose,
    onOpen = _useProjectSettingsCo.onOpen;
  return __jsx(ProjectSettingsBox, {
    mt: 6,
    pt: 6,
    pb: 0
  }, __jsx(VStack, null, __jsx(CTAButton, {
    width: "100%",
    onClick: function onClick() {
      onClose === null || onClose === void 0 || onClose();
      subscriptionPaywalls.openModal({
        onClose: function onClose() {
          return onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        },
        handleClickFreePlan: function handleClickFreePlan() {
          return onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        },
        reason: 'publish-cta',
        returnTo: ['project-settings-popover']
      });
    },
    id: 'publish-button',
    colorScheme: "purple",
    leftIcon: __jsx(ProBadgeIcon, {
      fill: "yellow.500"
    })
  }, "get hatch pro"), __jsx(Text, {
    fontSize: "sm"
  }, "Free accounts are limited to 3 published projects. There are no limits with Hatch Pro! Upgrade to publish more and get a free domain name.")));
};