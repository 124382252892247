var __jsx = React.createElement;
import { Box, InformationalUpgradeCTA } from '@playful/design_system';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';
export function CustomDomainCTA() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    onClose = _useProjectSettingsCo.onClose,
    onOpen = _useProjectSettingsCo.onOpen;
  return __jsx(ProjectSettingsBox, {
    mt: 6,
    pt: 6,
    pb: 0
  }, __jsx(InformationalUpgradeCTA, {
    onClick: function onClick() {
      onClose === null || onClose === void 0 || onClose();
      subscriptionPaywalls.openModal({
        onClose: function onClose() {
          return onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        },
        handleClickFreePlan: function handleClickFreePlan() {
          return onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        },
        reason: 'customdomain',
        successModal: 'subscribe-success-domain-cta',
        returnTo: ['project-settings-popover']
      });
    },
    heading: "Want a custom domain?",
    body: __jsx(React.Fragment, null, "Claim a free custom domain or connect your own when you", ' ', __jsx(Box, {
      as: "span",
      textDecoration: "underline"
    }, "upgrade to Hatch Pro"))
  }));
}