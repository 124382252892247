import { canvasToBlob } from '../canvas.js';
export class ImageThumbnail {
    url;
    imageElement;
    canvas;
    canvasContext;
    constructor(url) {
        this.url = url;
        const canvas = document.createElement('canvas');
        this.canvas = canvas;
        this.canvasContext = canvas.getContext('2d');
        this.imageElement = new Image();
    }
    getThumbnail(options) {
        return new Promise((resolve, reject) => {
            this.imageElement.onload = (imageLoadEvent) => {
                const loadedImage = imageLoadEvent.target;
                const scaleFactor = options.width / loadedImage.width;
                this.canvas.width = options.width;
                this.canvas.height = loadedImage.height * scaleFactor;
                this.canvasContext.drawImage(loadedImage, 0, 0, this.canvas.width, this.canvas.height);
                canvasToBlob(this.canvas, options.mimeType, options.quality)
                    .then((blob) => {
                    resolve(blob);
                })
                    .catch((error) => {
                    reject(error);
                });
            };
            this.imageElement.src = this.url;
        });
    }
}
