import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { FormControl } from '@chakra-ui/react';
import React from 'react';
export function SwitchField(props) {
  return __jsx(FormControl, _extends({
    display: "flex",
    py: 1,
    justifyContent: "space-between",
    alignItems: "center"
  }, props));
}