import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useRef, useState } from 'react';
var MIN_SIZE = 10;
var MAX_SIZE = 100;
/**
 * Provides color insights for a provided image.
 * Wraps the bare minimum bits and algos from https://github.com/fast-average-color/fast-average-color
 */
export var useColor = function useColor(props) {
  var imgRef = useRef(null);
  var _useState = useState(false),
    isComplete = _useState[0],
    setComplete = _useState[1];
  var _useState2 = useState(null),
    color = _useState2[0],
    setColor = _useState2[1];
  var onLoad = function onLoad() {
    if (props !== null && props !== void 0 && props.disabled || isComplete) return;
    var element = imgRef.current;
    var complete = element === null || element === void 0 ? void 0 : element.complete;
    if (complete) {
      setColor(getColor(element, props));
      setComplete(true);
    }
  };
  return _objectSpread({
    onLoad: onLoad,
    imgRef: imgRef
  }, color);
};
function getColor(resource, props) {
  var size = prepareSizeAndPosition({
    width: resource.naturalWidth,
    height: resource.naturalHeight
  });
  var canvas = document.createElement('canvas');
  canvas.width = size.destWidth;
  canvas.height = size.destHeight;
  var ctx = canvas.getContext('2d');
  ctx === null || ctx === void 0 || ctx.clearRect(0, 0, size.destWidth, size.destHeight);
  ctx === null || ctx === void 0 || ctx.drawImage(resource, size.srcLeft, size.srcTop, size.srcWidth, size.srcHeight, 0, 0, size.destWidth, size.destHeight);
  if (!size.destWidth || !size.destHeight) return null;
  var bitmapData = ctx === null || ctx === void 0 ? void 0 : ctx.getImageData(0, 0, size.destWidth, size.destHeight).data;
  if (!bitmapData) return null;
  var value = getColorFromBitmapData(bitmapData, props);
  return value && prepareResult(value);
}
var algorithms = {
  simple: simpleAlgorithm,
  sqrt: sqrtAlgorithm,
  dominant: dominantAlgorithm
};
function getColorFromBitmapData(arr) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var bytesPerPixel = 4;
  var _props$algorithm = props.algorithm,
    algo = _props$algorithm === void 0 ? 'sqrt' : _props$algorithm,
    _props$step = props.step,
    step = _props$step === void 0 ? 1 : _props$step;
  if (!arr) return;
  var arrLength = arr.length;
  var len = arrLength - arrLength % bytesPerPixel;
  var algorithm = algorithms[algo];
  return algorithm(arr, len, {
    step: step * bytesPerPixel
  });
}
function prepareSizeAndPosition(originalSize) {
  var srcLeft = 0;
  var srcTop = 0;
  var srcWidth = originalSize.width;
  var srcHeight = originalSize.height;
  var destWidth = srcWidth;
  var destHeight = srcHeight;
  var factor;
  if (srcWidth > srcHeight) {
    factor = srcWidth / srcHeight;
    destWidth = MAX_SIZE;
    destHeight = Math.round(destWidth / factor);
  } else {
    factor = srcHeight / srcWidth;
    destHeight = MAX_SIZE;
    destWidth = Math.round(destHeight / factor);
  }
  if (destWidth > srcWidth || destHeight > srcHeight || destWidth < MIN_SIZE || destHeight < MIN_SIZE) {
    destWidth = srcWidth;
    destHeight = srcHeight;
  }
  return {
    srcLeft: srcLeft,
    srcTop: srcTop,
    srcWidth: srcWidth,
    srcHeight: srcHeight,
    destWidth: destWidth,
    destHeight: destHeight
  };
}
function prepareResult(value) {
  var rgb = value.slice(0, 3);
  var rgba = [].concat(_toConsumableArray(rgb), [value[3] / 255]);
  var isDarkColor = isDark(value);
  return {
    value: value,
    rgb: "rgb(".concat(rgb.join(','), ")"),
    rgba: "rgba(".concat(rgba.join(','), ")"),
    hex: arrayToHex(rgb),
    hexa: arrayToHex(value),
    isDark: isDarkColor,
    isLight: !isDarkColor
  };
}
function toHex(num) {
  var str = num.toString(16);
  return str.length === 1 ? "0".concat(str) : str;
}
function arrayToHex(arr) {
  return "#".concat(arr.map(toHex).join(''));
}
function isDark(color) {
  // http://www.w3.org/TR/AERT#color-contrast
  var result = (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;
  return result < 128;
}
function dominantAlgorithm(arr, len, options) {
  var colorHash = {};
  var divider = 24;
  var step = options.step;
  var max = [0, 0, 0, 0, 0];
  if (!arr) return;
  for (var i = 0; i < len; i += step) {
    var red = arr[i];
    var green = arr[i + 1];
    var blue = arr[i + 2];
    var alpha = arr[i + 3];
    var key = Math.round(red / divider) + ',' + Math.round(green / divider) + ',' + Math.round(blue / divider);
    if (colorHash[key]) {
      colorHash[key] = [colorHash[key][0] + red * alpha, colorHash[key][1] + green * alpha, colorHash[key][2] + blue * alpha, colorHash[key][3] + alpha, colorHash[key][4] + 1];
    } else {
      colorHash[key] = [red * alpha, green * alpha, blue * alpha, alpha, 1];
    }
    if (max[4] < colorHash[key][4]) {
      max = colorHash[key];
    }
  }
  var redTotal = max[0];
  var greenTotal = max[1];
  var blueTotal = max[2];
  var alphaTotal = max[3];
  var count = max[4];
  return [Math.round(redTotal / alphaTotal), Math.round(greenTotal / alphaTotal), Math.round(blueTotal / alphaTotal), Math.round(alphaTotal / count)];
}
function simpleAlgorithm(arr, len, options) {
  var redTotal = 0;
  var greenTotal = 0;
  var blueTotal = 0;
  var alphaTotal = 0;
  var count = 0;
  if (!arr) return;
  var step = options.step;
  for (var i = 0; i < len; i += step) {
    var alpha = arr[i + 3];
    var red = arr[i] * alpha;
    var green = arr[i + 1] * alpha;
    var blue = arr[i + 2] * alpha;
    redTotal += red;
    greenTotal += green;
    blueTotal += blue;
    alphaTotal += alpha;
    count++;
  }
  return [Math.round(redTotal / alphaTotal), Math.round(greenTotal / alphaTotal), Math.round(blueTotal / alphaTotal), Math.round(alphaTotal / count)];
}
function sqrtAlgorithm(arr, len, options) {
  var redTotal = 0;
  var greenTotal = 0;
  var blueTotal = 0;
  var alphaTotal = 0;
  var count = 0;
  if (!arr) return;
  var step = options.step;
  for (var i = 0; i < len; i += step) {
    var red = arr[i];
    var green = arr[i + 1];
    var blue = arr[i + 2];
    var alpha = arr[i + 3];
    redTotal += red * red * alpha;
    greenTotal += green * green * alpha;
    blueTotal += blue * blue * alpha;
    alphaTotal += alpha;
    count++;
  }
  return [Math.round(Math.sqrt(redTotal / alphaTotal)), Math.round(Math.sqrt(greenTotal / alphaTotal)), Math.round(Math.sqrt(blueTotal / alphaTotal)), Math.round(alphaTotal / count)];
}