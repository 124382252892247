import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["label"];
var __jsx = React.createElement;
import { Box } from '@playful/design_system';
import React from 'react';
import { SlugEditor } from '../components/SlugEditor';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsFormLabel } from './ProjectSettingsLayout';
export function ChooseSlug(props) {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    slug = _useProjectSettingsCo.slug,
    currentDomain = _useProjectSettingsCo.currentDomain,
    updatedDomain = _useProjectSettingsCo.updatedDomain,
    slugErrMsg = _useProjectSettingsCo.slugErrMsg,
    isLoading = _useProjectSettingsCo.isLoading,
    onSlugChange = _useProjectSettingsCo.onSlugChange,
    slugInputValue = _useProjectSettingsCo.slugInputValue,
    setSlugInputValue = _useProjectSettingsCo.setSlugInputValue,
    updatedSlug = _useProjectSettingsCo.updatedSlug;
  var label = props.label,
    inputProps = _objectWithoutProperties(props, _excluded);
  var slugHintText = slugErrMsg || "".concat(updatedDomain, "/").concat(updatedSlug || slug);
  return __jsx(Box, {
    my: 4
  }, __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'project-url',
    mb: 1
  }, label || currentDomain), __jsx(SlugEditor, _extends({
    id: "project-url",
    slug: slug,
    isDisabled: isLoading,
    inputValue: slugInputValue,
    setInputValue: setSlugInputValue,
    onChange: onSlugChange,
    placeholder: 'add project name',
    status: !!slugErrMsg ? 'error' : 'initial',
    slugHint: __jsx(React.Fragment, null, slugHintText),
    autoComplete: 'off'
  }, inputProps)));
}