import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { FormHelperText } from '@chakra-ui/react';
import { Box, Divider, ExternalLinkIcon, FormControl, Input, Link, ProBadgeIcon, Text, Textarea, theme, Tooltip } from '@playful/design_system';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import { FEATURE_INTERNAL_TOOLS } from '@playful/api';
import React from 'react';
import { PasswordProtectSetting } from './PasswordProtectSetting';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsFormLabel } from './ProjectSettingsLayout';
import { useUserContext } from '../user/UserContext';
export function ProjectSettingsForm() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    googleAnalyticsIdInputValue = _useProjectSettingsCo.googleAnalyticsIdInputValue,
    onGoogleAnalyticsIdInputValue = _useProjectSettingsCo.onGoogleAnalyticsIdInputValue,
    googleAnalyticsErrMsg = _useProjectSettingsCo.googleAnalyticsErrMsg,
    customHeaderContentInputValue = _useProjectSettingsCo.customHeaderContentInputValue,
    onCustomHeaderContentInputValue = _useProjectSettingsCo.onCustomHeaderContentInputValue,
    pageMetadataInput = _useProjectSettingsCo.pageMetadataInput,
    onPageMetadataInputChange = _useProjectSettingsCo.onPageMetadataInputChange,
    projectInfo = _useProjectSettingsCo.projectInfo;
  var _useUserContext = useUserContext(),
    hasFlag = _useUserContext.hasFlag;
  var handleProClick = subscriptionPaywalls.addGoogleAnalytics() ? undefined : function () {
    return subscriptionPaywalls.openModal({
      reason: 'add-google-analytics'
    });
  };
  function handleChange(e) {
    onPageMetadataInputChange(_objectSpread(_objectSpread({}, pageMetadataInput), {}, _defineProperty({}, e.target.name, e.target.value)));
  }
  return __jsx(React.Fragment, null, __jsx(PasswordProtectSetting, null), __jsx(Divider, {
    variant: "thin"
  }), __jsx(Text, {
    fontSize: "sm",
    fontWeight: "medium",
    color: "gray.600",
    alignSelf: "flex-start"
  }, "SEO settings"), __jsx(FormControl, null, __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'site-title'
  }, "site title"), __jsx(Input, {
    id: 'site-title',
    name: 'title',
    placeholder: (projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.title) || 'add your meta title here',
    variant: "filled",
    value: (pageMetadataInput === null || pageMetadataInput === void 0 ? void 0 : pageMetadataInput.title) || '',
    onChange: handleChange
  })), __jsx(FormControl, null, __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'site-description'
  }, "site description"), __jsx(Textarea, {
    id: 'site-description',
    name: 'description',
    placeholder: 'add your meta description here',
    variant: "filled",
    value: (pageMetadataInput === null || pageMetadataInput === void 0 ? void 0 : pageMetadataInput.description) || '',
    onChange: handleChange,
    multiple: true
  })), __jsx(FormControl, {
    mb: 2,
    mt: 0
  }, __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'google-analytics-id',
    size: 'md',
    mb: 1,
    fontWeight: "normal",
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    onClick: handleProClick
  }, "google analytics id", !subscriptionPaywalls.addGoogleAnalytics() && __jsx(Tooltip, {
    hasArrow: true,
    offset: [0, 12],
    placement: "top",
    label: "upgrade to Hatch Pro to add your Google Analytics ID."
  }, __jsx(Box, {
    as: "span",
    cursor: "help"
  }, __jsx(ProBadgeIcon, {
    h: 5,
    w: 5,
    "data-testid": 'watermark-pro-badge'
  }))), __jsx(Link, {
    href: 'https://support.google.com/analytics/answer/9539598?hl=en',
    isExternal: true,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    marginLeft: 'auto'
  }, "find my id ", __jsx(ExternalLinkIcon, {
    size: 'xs',
    w: 3,
    h: 3
  }))), __jsx(Input, {
    id: 'google-analytics-id',
    placeholder: 'G-XXXXXXXX',
    variant: "filled",
    value: googleAnalyticsIdInputValue,
    spellCheck: false,
    onChange: function onChange(e) {
      if (subscriptionPaywalls.addGoogleAnalytics()) {
        onGoogleAnalyticsIdInputValue(e.target.value);
      }
    },
    onClick: handleProClick,
    maxLength: 30
  }), !!googleAnalyticsErrMsg && __jsx(FormHelperText, {
    color: 'red'
  }, googleAnalyticsErrMsg)), hasFlag(FEATURE_INTERNAL_TOOLS) && __jsx(FormControl, {
    mb: 2,
    mt: 0,
    position: "relative"
  }, __jsx("div", {
    style: theme.indicators.internalToolStyle
  }), __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'custom-header-content',
    size: 'md',
    mb: 1,
    fontWeight: "normal",
    display: 'flex',
    gap: 2,
    alignItems: 'center'
  }, __jsx(Tooltip, {
    label: "Add custom header content such as other analytics scripts to your site. Be sure to include opening and closing tags when needed, like: <script> and </script> tags."
  }, "custom header content")), __jsx(Textarea, {
    id: 'custom-header-content',
    variant: "filled",
    value: customHeaderContentInputValue,
    spellCheck: false,
    onChange: function onChange(e) {
      onCustomHeaderContentInputValue(e.target.value);
    }
  })));
}