import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
var __jsx = React.createElement;
import { ModalFooter, useDisclosure } from '@chakra-ui/react';
import { Box, Link } from '@playful/design_system';
import React from 'react';
import { CTAButton } from '../components/CallToAction/CTAButton';
import CustomDomainSetupDialog from '../user/Account/CustomDomainSetupDialog';
export function DomainStatusBlock(_ref) {
  var domain = _ref.domain;
  var _useDisclosure = useDisclosure(),
    isOpen = _useDisclosure.isOpen,
    onOpen = _useDisclosure.onOpen,
    onClose = _useDisclosure.onClose;
  if (domain.status === 'PROVISIONING') {
    return __jsx(InfoBlock, {
      backgroundColor: 'yellow.100'
    }, "Your domain is being verified. You can still publish, but your site won\u2019t appear until the verification has completed.", __jsx("p", null, __jsx(Link, {
      textDecoration: 'underline',
      onClick: onOpen
    }, "view setup steps")), __jsx(CustomDomainSetupDialog, {
      isOpen: isOpen,
      onClose: onClose,
      domain: domain,
      isPublishing: true,
      title: 'connect your domain',
      Footer: __jsx(ModalFooter, {
        pt: 0,
        mt: -4
      }, __jsx(CTAButton, {
        onClick: onClose
      }, "return to publishing"))
    }));
  }
  if (domain.status === 'FAILED') {
    return __jsx(InfoBlock, {
      backgroundColor: 'red.100'
    }, "We have not been able to add your domain. Please click the link below to learn more and verify your domain information.", __jsx("p", null, __jsx(Link, {
      textDecoration: 'underline',
      target: '_blank',
      href: '/faqs'
    }, "troubleshoot domain errors")));
  }
  return null;
}
function InfoBlock(_ref2) {
  var children = _ref2.children,
    boxProps = _objectWithoutProperties(_ref2, _excluded);
  return __jsx(Box, _extends({
    mt: 1,
    mb: 3,
    px: 3,
    py: 2,
    borderRadius: '8px',
    background: 'yellow.100',
    fontSize: '12px'
  }, boxProps), children);
}