var __jsx = React.createElement;
import { Box, Button, PublishRocketIcon } from '@playful/design_system';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
import { useProjectSettingsContext } from './ProjectSettingsContext';
export function PublishButton(_ref) {
  var isLoading = _ref.isLoading;
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isPublished = _useProjectSettingsCo.isPublished,
    onPublished = _useProjectSettingsCo.onPublished,
    canPublish = _useProjectSettingsCo.canPublish,
    projectInfo = _useProjectSettingsCo.projectInfo;
  var handleClick = function handleClick() {
    onPublished();
  };
  return __jsx(Box, {
    mt: 4
  }, !isPublished && subscriptionPaywalls.publishProject(projectInfo) && __jsx(Button, {
    width: '100%',
    onClick: handleClick,
    isDisabled: !canPublish,
    id: 'publish-button',
    isLoading: isLoading,
    variant: "rebrand-pill",
    colorScheme: "primary",
    leftIcon: __jsx(PublishRocketIcon, {
      mt: "2"
    })
  }, "publish it"), isPublished && __jsx(Button, {
    id: 'unpublish-button',
    width: '100%',
    onClick: handleClick,
    size: 'md',
    variant: 'rebrand-pill',
    colorScheme: 'danger',
    height: '40px',
    isLoading: isLoading
  }, "unpublish"));
}