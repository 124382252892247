var __jsx = React.createElement;
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
import { CustomDomainCTA } from './CustomDomainCTA';
import { CustomDomainProCTA } from './CustomDomainProCTA';
import { GetHatchProCTA } from './GetHatchProCTA';
import { useProjectSettingsContext } from './ProjectSettingsContext';
export var ProjectSettingsCTAs = function ProjectSettingsCTAs() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isPublished = _useProjectSettingsCo.isPublished,
    projectInfo = _useProjectSettingsCo.projectInfo,
    isEditingPublicUrl = _useProjectSettingsCo.isEditingPublicUrl;

  // Use isn't a subscriber and has reached published project limit
  var showGetHatchProCTA = !isPublished && !subscriptionPaywalls.publishProject(projectInfo);

  // User isn't a subscriber and hasn't reached published project limit
  var showCustomDomainCTA = !showGetHatchProCTA && (!isPublished || isEditingPublicUrl) && !subscriptionPaywalls.customDomain();

  // User is a subscriber
  var showProCustomDomainCTA = (!isPublished || isEditingPublicUrl) && subscriptionPaywalls.hasActiveSubscription();
  return __jsx(React.Fragment, null, showGetHatchProCTA && __jsx(GetHatchProCTA, null), showCustomDomainCTA && __jsx(CustomDomainCTA, null), showProCustomDomainCTA && __jsx(CustomDomainProCTA, null));
};