import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createStandaloneToast, useToast } from '@chakra-ui/react';
import { AppToast, theme } from '@playful/design_system';
import { generateUUID } from '@playful/utils';
import React, { useEffect } from 'react';
import { proxy, ref, useSnapshot } from 'valtio';

// Theme must be passed to createStandaloneToast: https://v2.chakra-ui.com/docs/components/toast/usage#standalone-toasts
var _createStandaloneToas = createStandaloneToast({
    theme: theme
  }),
  toast = _createStandaloneToas.toast,
  ToastContainer = _createStandaloneToas.ToastContainer;
// For 16px offset from header:
// 48 is height of the header + 8px for padding
export var TOAST_TOP_OFFSET = 48 + 8;
var initialToastState = {
  toasts: []
};
export var toastState = proxy(initialToastState);
export var addToast = function addToast(toast) {
  var id = toast.id,
    _toast$status = toast.status,
    status = _toast$status === void 0 ? 'info' : _toast$status,
    title = toast.title,
    description = toast.description,
    _toast$position = toast.position,
    position = _toast$position === void 0 ? 'bottom' : _toast$position,
    duration = toast.duration,
    offset = toast.offset;
  var exists = toastState.toasts.find(function (t) {
    return t.id === id;
  });
  if (exists) return;
  toastState.toasts.push(
  // Use Valtio's ref() to prevent messages that are ReactNodes from being proxy-wrapped
  // which seems to confuse React downstream.
  ref({
    id: id,
    title: title,
    description: description,
    status: status,
    position: position,
    duration: duration,
    offset: offset
  }));
  return id;
};
export var addErrorToast = function addErrorToast(message, options) {
  var t = _objectSpread({
    id: generateUUID(),
    description: typeof message !== 'string' ? JSON.stringify(message) : message,
    status: 'error',
    position: 'top',
    offset: {
      top: TOAST_TOP_OFFSET
    }
  }, options);
  return addToast(t);
};
export var addSuccessToast = function addSuccessToast(message, options) {
  var t = _objectSpread({
    id: generateUUID(),
    description: message,
    status: 'success'
  }, options);
  return addToast(t);
};
export var addInfoToast = function addInfoToast(message, options) {
  var t = _objectSpread({
    id: generateUUID(),
    description: message,
    status: 'info'
  }, options);
  return addToast(t);
};
export var removeToast = function removeToast(toastId) {
  toastState.toasts = toastState.toasts.filter(function (t) {
    return t.id !== toastId;
  });
  toast.close(toastId);
};
export var baseUseToastOptions = {
  variant: 'toast',
  isClosable: true
};
export default function AppToasts() {
  var _useSnapshot = useSnapshot(toastState),
    toasts = _useSnapshot.toasts;
  var toast = useToast();
  useEffect(function () {
    toasts.map(function (t) {
      var customToast = function customToast() {
        return __jsx(AppToast, null, t.description);
      };
      var id = t.id,
        _t$status = t.status,
        status = _t$status === void 0 ? 'info' : _t$status,
        title = t.title,
        description = t.description,
        duration = t.duration,
        _t$position = t.position,
        position = _t$position === void 0 ? 'bottom' : _t$position,
        offset = t.offset;
      var offsetContainerStyle = {};
      if (offset && offset !== undefined) {
        offsetContainerStyle = {
          position: 'relative'
        };
        if (offset.top) {
          offsetContainerStyle.top = offset.top + 'px';
        }
        if (offset.right) {
          offsetContainerStyle.right = offset.right + 'px';
        }
        if (offset.bottom) {
          offsetContainerStyle.bottom = offset.bottom + 'px';
        }
        if (offset.left) {
          offsetContainerStyle.left = offset.left + 'px';
        }
      }
      var toastOptions = _objectSpread(_objectSpread({}, baseUseToastOptions), {}, {
        id: id,
        status: status,
        title: title,
        description: description,
        duration: duration,
        // If null, toast stays open until dismissed
        position: position,
        containerStyle: _objectSpread({}, offsetContainerStyle),
        onCloseComplete: function onCloseComplete() {
          removeToast(id);
        }
      });

      // If t.status is not provided, default to the gray custom toast
      if (status === undefined || status === 'info') {
        toastOptions.render = customToast;
      }
      if (!toast.isActive(id)) {
        toast(toastOptions);
      }
    });
  }, [toast, toasts, toasts.length]);
  return __jsx(ToastContainer, null);
}