/**
 * Convert a canvas into a blob. Detect if toBlob is not supported and generate manually-ish if it's not.
 *
 * @param canvas
 * @param mimeType Requested format
 * @param quality Quality setting for image/jpeg and image/webp
 */
export function canvasToBlob(canvas, mimeType = 'image/jpeg', quality = 1) {
    return new Promise((resolve, reject) => {
        if (!canvas.toBlob) {
            const binStr = atob(canvas.toDataURL(mimeType, quality).split(',')[1]);
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }
            const blob = new Blob([arr], { type: mimeType });
            resolve(blob);
        }
        else {
            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(blob);
                }
                else {
                    reject('Canvas toBlob did not return a blob.');
                }
            }, mimeType, quality);
        }
    });
}
