import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["children"];
var __jsx = React.createElement;
import { createGenericContext } from '@playful/utils';
import React from 'react';
var _createGenericContext = createGenericContext(),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  hook = _createGenericContext2[0],
  GenericProvider = _createGenericContext2[1];
export var useProjectSettingsContext = hook;
export function ProjectSettingsProvider(props) {
  var children = props.children,
    rest = _objectWithoutProperties(props, _excluded);
  return __jsx(GenericProvider, {
    value: rest
  }, children);
}