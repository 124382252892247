import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { Box, Button, FormLabel, IconButton, Input, Stack, Text, theme } from '@chakra-ui/react';
import { FileUploadIcon, TrashIcon } from '@playful/design_system';
import { playContentMimeType } from '@playful/runtime';
import { withF, generateUUID } from '@playful/utils';
import { fetchUrlData } from '../workbench/project/resources';
import React, { useEffect, useRef, useState } from 'react';
import { useColor } from '../hooks/useColor';
import { useDragAndDrop } from '../hooks/useDragAndDrop';
export var createFilePreview = function createFilePreview(_file) {
  var isStrFilePath = _file && typeof _file === 'string';
  return isStrFilePath ? _file : _file && URL.createObjectURL(_file);
};
export function FileInput(_ref) {
  var innerButton = _ref.innerButton,
    disabled = _ref.disabled,
    allowedInputFileTypes = _ref.allowedInputFileTypes,
    allowedTypes = _ref.allowedTypes,
    onAdd = _ref.onAdd,
    onReset = _ref.onReset,
    file = _ref.file,
    hint = _ref.hint,
    _ref$inputId = _ref.inputId,
    inputId = _ref$inputId === void 0 ? 'upload-component-thumbnail' : _ref$inputId,
    isLoading = _ref.isLoading,
    onOpen = _ref.onOpen,
    _ref$BoxProps = _ref.BoxProps,
    BoxProps = _ref$BoxProps === void 0 ? {} : _ref$BoxProps;
  var inputRef = useRef(null);
  var preview = createFilePreview(file);
  var _useState = useState(false),
    overDrop = _useState[0],
    setOverDrop = _useState[1];
  var _useColor = useColor({
      step: 16
    }),
    imgRef = _useColor.imgRef,
    onLoad = _useColor.onLoad;

  // because we can't set the state of a file input,
  // use it only to accessibly select a file, but otherwise keep the value clear
  // so it doesn't have the wrong file attached vs what was potentially dragged
  // or passed in.
  useEffect(function () {
    if (inputRef.current) inputRef.current.value = '';
  }, [file]);
  function reset(e) {
    e.preventDefault();
    e.stopPropagation();
    onReset === null || onReset === void 0 || onReset();
  }
  function handleSelection(file) {
    if (!file) return;
    onAdd === null || onAdd === void 0 || onAdd(file);
  }
  var handleDrop = function handleDrop(event, options) {
    var _event$dataTransfer, _event$dataTransfer2;
    var _ref2 = ((_event$dataTransfer = event.dataTransfer) === null || _event$dataTransfer === void 0 ? void 0 : _event$dataTransfer.files) || [],
      _ref3 = _slicedToArray(_ref2, 1),
      droppedFile = _ref3[0];
    if (droppedFile) {
      if (!options.canDrop) return;
      setOverDrop(false);
      handleSelection(droppedFile);
      return;
    }
    var contentJson = (_event$dataTransfer2 = event.dataTransfer) === null || _event$dataTransfer2 === void 0 ? void 0 : _event$dataTransfer2.getData(playContentMimeType);
    if (!droppedFile && contentJson) {
      var content = JSON.parse(contentJson);
      if (content && content.type === 'image' || content.type === 'video' || content.type === 'audio') {
        fetchUrlData(content.url).then(function (blob) {
          var file = new File([blob], (content === null || content === void 0 ? void 0 : content.name) || generateUUID(), {
            type: blob.type
          });
          handleSelection(file);
        });
        setOverDrop(false);
      }
    }
  };
  var _useDragAndDrop = useDragAndDrop({
      disabled: disabled,
      allowedTypes: allowedTypes,
      onDrop: handleDrop,
      onDragIn: function onDragIn(_, options) {
        return setOverDrop(options.canDrop);
      },
      onDragOut: withF(setOverDrop),
      dragEffect: 'move'
    }),
    _useDragAndDrop2 = _slicedToArray(_useDragAndDrop, 1),
    dropRef = _useDragAndDrop2[0];

  // Overall dimensions for File Upload
  var HEIGHT = '100px';
  var WIDTH = '192px';
  return __jsx(React.Fragment, null, __jsx(Box, _extends({
    ref: dropRef,
    border: "1px ".concat(overDrop ? theme.colors.gray['400'] : theme.colors.gray['200'], " dashed"),
    borderRadius: theme.radii['lg'],
    boxSizing: 'content-box',
    position: 'relative',
    backgroundColor: 'gray.50',
    _hover: {
      backgroundColor: 'gray.100'
    },
    color: 'gray.700',
    maxWidth: '192px',
    maxHeight: '100px'
  }, BoxProps), preview && !disabled && __jsx(IconButton, {
    size: "xs",
    "aria-label": "Remove",
    onClick: reset,
    position: 'absolute',
    top: preview ? '.25rem' : '-.5rem',
    right: preview ? '.25rem' : '-.5rem',
    backgroundColor: 'white',
    border: "1px ".concat(theme.colors.gray['200'], " solid"),
    zIndex: 3,
    _focus: {
      outline: '1px black solid'
    }
  }, __jsx(TrashIcon, null)), __jsx(Box, {
    position: 'relative',
    filter: overDrop ? 'blur(3px)' : 'none'
  }, __jsx(Input, _extends({
    ref: inputRef,
    isDisabled: disabled
  }, allowedInputFileTypes && {
    accept: allowedInputFileTypes
  }, {
    type: "file",
    id: inputId,
    onChange: function onChange(e) {
      var _e$target;
      return handleSelection((_e$target = e.target) === null || _e$target === void 0 || (_e$target = _e$target.files) === null || _e$target === void 0 ? void 0 : _e$target[0]);
    },
    position: 'absolute',
    left: 0,
    top: 0
    // For cursor: pointer to work over the FileInput, we must hide the default file input button
    ,
    width: '0',
    height: '0',
    opacity: 0,
    "aria-hidden": "true",
    onClick: onOpen,
    border: 0
  })), __jsx(FormLabel, {
    htmlFor: inputId,
    w: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: "pointer",
    width: WIDTH,
    height: HEIGHT,
    overflow: 'hidden',
    mb: 0,
    mr: 0
  }, __jsx(Button, {
    display: 'flex',
    justifyContent: 'center',
    isLoading: isLoading,
    whiteSpace: 'normal',
    background: 'transparent',
    pointerEvents: 'none',
    width: WIDTH,
    height: HEIGHT,
    cursor: "pointer",
    p: 0
  }, preview ? __jsx("img", {
    onLoad: onLoad,
    ref: imgRef,
    style: {
      height: '100%',
      objectFit: 'contain'
    },
    src: preview
  }) : __jsx(Stack, {
    display: "flex",
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    overflow: 'visible',
    gap: 0
  }, __jsx(FileUploadIcon, {
    w: 6,
    h: 6,
    alignSelf: 'center'
  }), __jsx(Text, {
    fontSize: '12px',
    fontWeight: 500,
    whiteSpace: 'nowrap'
  }, "upload image")))))), !disabled && hint && __jsx(Text, {
    width: '140px',
    display: 'block'
  }, hint));
}