import { AudioWaveform } from './AudioWaveform.js';
import { ImageThumbnail } from './ImageThumbnail.js';
import { getVideoThumbnail } from './VideoThumbnail.js';
/**
 * Create a thumbnail
 * @param url
 * @param arrayBuffer
 * @param mimeType
 */
export async function createResourceThumbnail(url, arrayBuffer, mimeType) {
    let blob;
    try {
        if (mimeType.startsWith('image/')) {
            blob = await createImageThumbnail(url, mimeType);
        }
        if (mimeType.startsWith('audio/')) {
            blob = await createAudioThumbnail(arrayBuffer.slice(0));
        }
        if (mimeType.startsWith('video/')) {
            blob = await createVideoThumbnail(url);
        }
    }
    catch (error) {
        // TODO: Track or log these errors.
        // Thumbnails aren't technically required so don't kill the whole process.
        console.error(error);
    }
    return blob;
}
export async function createVideoThumbnail(videoUrl) {
    return getVideoThumbnail(videoUrl, { quality: 1, scale: 1, start: 1 });
}
export async function createAudioThumbnail(audioArrayBuffer) {
    const audioWaveform = new AudioWaveform(audioArrayBuffer);
    return audioWaveform.getThumbnail({
        width: 150,
        height: 125,
        channel: 0,
        canvasWidth: 150,
        canvasHeight: 125,
        fillStyle: '#a9a9a9',
        waveStrokeStyle: '#fff',
    });
}
export async function createImageThumbnail(imageUrl, mimeType, options) {
    options = {
        width: 150,
        quality: 1,
        mimeType,
        ...options,
    };
    const imageThumbnail = new ImageThumbnail(imageUrl);
    return imageThumbnail.getThumbnail(options);
}
