import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createGenericContext } from '@playful/utils';
import { useIntersectionObserved, useIntersectionObserver } from '../../hooks/useIntersectionObserver.jsx';
var _createGenericContext = createGenericContext({
    displayName: 'VirtualList'
  }),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  useVirtualCtx = _createGenericContext2[0],
  VirtualProvider = _createGenericContext2[1];

/**
 * hook to wrap around the items you want to observe for visibility.
 */
export function useVirtualListItem(_ref) {
  var el = _ref.el;
  var virtualItem = useVirtualCtx();
  useIntersectionObserved(virtualItem.observer, el);
  return virtualItem;
}

/**
 * uses a combination of intersection observer along with an optional hook to progressively render or
 * window a list of items based on their visibility for added performance. supports non-contiguous items
 * as it's both key and visibility based (vs indexes).
 * two hooks are provided:
 * - `useVirtualListItem` is used to wrap the items you want to observe and conditionally handle visibility for.
 * - `useVirtualizedList` can be used to add the progressive rendering or windowing if additional performance
 *   is needed. can be used across multiple lists inside the same VirtualList for grouping.
 */
export function VirtualList(_ref2) {
  var observerOptions = _ref2.observerOptions,
    onIntersectionChange = _ref2.onIntersectionChange,
    children = _ref2.children;
  var _useState = useState(new Set()),
    visibleKeys = _useState[0],
    setVisibleKeys = _useState[1];
  var hasRendered = useRef(new Set());
  var vkr = useRef(visibleKeys);
  var observer = useIntersectionObserver(function (entries, observer) {
    var vi = new Set(vkr.current);
    var _iterator = _createForOfIteratorHelper(entries),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var entry = _step.value;
        var key = entry.target.dataset.key;
        if (!key) continue;
        if (entry.isIntersecting) {
          vi.add(key);
          hasRendered.current.add(key);
        } else {
          vi.delete(key);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    onIntersectionChange === null || onIntersectionChange === void 0 || onIntersectionChange(vi, entries, observer);
    vkr.current = vi;

    // don't re-render if sets are identical
    setVisibleKeys(function (prev) {
      return vi.difference(prev).size ? vi : prev;
    });
  }, observerOptions);
  return __jsx(VirtualProvider, {
    value: {
      observer: observer,
      visibleKeys: visibleKeys,
      renderedKeys: hasRendered.current
    }
  }, children);
}
/**
 * optional hook to help with lists where rendering too many components is too heavy, even with
 * content-visibility, or in browsers where it's not supported.
 * by passing an item height, it will window the items with a rolling min/max.
 * without an item height, it'll progressively add to the list, like an infinite scroll.
 */
export function useVirtualizedList(list, extractKey) {
  var _ref3 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    _ref3$startingMax = _ref3.startingMax,
    startingMax = _ref3$startingMax === void 0 ? 100 : _ref3$startingMax,
    _ref3$buffer = _ref3.buffer,
    buffer = _ref3$buffer === void 0 ? 50 : _ref3$buffer,
    _ref3$itemHeight = _ref3.itemHeight,
    itemHeight = _ref3$itemHeight === void 0 ? 0 : _ref3$itemHeight;
  var _useVirtualCtx = useVirtualCtx(),
    visibleKeys = _useVirtualCtx.visibleKeys;
  var visibleKeysArr = useMemo(function () {
    return Array.from(visibleKeys);
  }, [visibleKeys]);
  var keys = useMemo(function () {
    return list.map(extractKey);
  }, [list, extractKey]);

  // visibleKeysArr should never be less than the keys passed in, but just in case
  var allInView = useMemo(function () {
    return keys.length <= visibleKeysArr.length;
  }, [visibleKeysArr, keys]);
  var firstItemInView = useMemo(function () {
    return visibleKeysArr[0] === keys[0];
  }, [visibleKeysArr, keys]);
  var lastItemInView = useMemo(function () {
    return visibleKeysArr.slice(-1)[0] === keys.slice(-1)[0];
  }, [visibleKeysArr, keys]);
  var startInView = allInView || firstItemInView;
  var endInView = allInView || lastItemInView;
  var getKeyIndex = useCallback(function (k) {
    return keys.indexOf(k);
  }, [keys]);
  var visibleIndexes = useMemo(function () {
    return visibleKeysArr.map(getKeyIndex);
  }, [visibleKeysArr, getKeyIndex]);

  // based on the keys passed in and the visibleKeys, determine the visible indices window.
  // only set this if we have an itemHeight—otherwise, it's just 0.
  var min = useMemo(function () {
    return itemHeight && !startInView ? Math.max(Math.min.apply(Math, _toConsumableArray(visibleIndexes).concat([0])) - buffer, 0) : 0;
  }, [visibleIndexes, startInView, itemHeight, buffer]);
  var max = useMemo(function () {
    return !endInView ? Math.max(Math.max.apply(Math, _toConsumableArray(visibleIndexes)) + buffer, startingMax) : keys.length;
  }, [visibleIndexes, startingMax, buffer, keys, endInView]);

  // slice the keys in the list to only show the ones that are visible
  var listWindow = useMemo(function () {
    return keys.slice(min, max);
  }, [keys, min, max]);
  return {
    keysInWindow: listWindow,
    style: {
      paddingTop: min * itemHeight,
      paddingBottom: Math.min(keys.length - max, 0) * itemHeight
    }
  };
}