import { jsx as _jsx } from "react/jsx-runtime";
import { Tab as ChTab, TabList as ChTabList, TabPanel as ChTabPanel, TabPanels as ChTabPanels, Tabs as ChTabs, } from '@chakra-ui/tabs';
/**
 * Tabs component. See [Tabs](https://v1.chakra-ui.com/docs/components/disclosure/tabs) for the complete documentation.
 */
export const Tabs = (props) => {
    return _jsx(ChTabs, { ...props });
};
export const Tab = (props) => {
    return _jsx(ChTab, { ...props });
};
export const TabList = (props) => {
    return _jsx(ChTabList, { ...props });
};
export const TabPanels = (props) => {
    return _jsx(ChTabPanels, { ...props });
};
export const TabPanel = (props) => {
    return _jsx(ChTabPanel, { ...props });
};
