var __jsx = React.createElement;
import { VStack } from '@chakra-ui/react';
import { Box, InformationalUpgradeCTA, Link } from '@playful/design_system';
import { useHoverDomains } from '../workbench/useHoverDomains';
import React from 'react';
import { useUserContext } from '../user/UserContext';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';
export var CustomDomainProCTA = function CustomDomainProCTA() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    setIsDomainModalOpen = _useProjectSettingsCo.setIsDomainModalOpen;
  var _useUserContext = useUserContext(),
    hoverVoucherState = _useUserContext.hoverVoucherState;
  var userHasClaimedFreeDomain = hoverVoucherState === 'redeemed';
  var _useHoverDomains = useHoverDomains({}),
    redirectToHover = _useHoverDomains.redirectToHover;
  return __jsx(ProjectSettingsBox, {
    mt: 6,
    pt: 6,
    pb: 0
  }, __jsx(VStack, {
    w: "100%"
  }, !userHasClaimedFreeDomain && __jsx(InformationalUpgradeCTA, {
    isProMember: true,
    onClick: redirectToHover,
    heading: "Claim your free domain!",
    body: __jsx(React.Fragment, null, "As a Hatch Pro subscriber, you get to", ' ', __jsx(Box, {
      as: "span",
      textDecoration: "underline"
    }, "choose a free custom domain"))
  }), userHasClaimedFreeDomain && __jsx(Link, {
    w: "100%",
    size: "md",
    fontWeight: 'medium',
    fontSize: '14px',
    onClick: redirectToHover
  }, "purchase a custom domain"), __jsx(Link, {
    w: "100%",
    size: "md",
    fontWeight: 'medium',
    fontSize: '14px',
    onClick: function onClick() {
      setIsDomainModalOpen(true);
    }
  }, "connect a domain you own")));
};