import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["tooltipLabel"];
var __jsx = React.createElement;
import { FormLabel } from '@chakra-ui/react';
import { Tooltip } from '@playful/design_system';
import React, { Fragment, useMemo } from 'react';
export function SwitchLabel(_ref) {
  var tooltipLabel = _ref.tooltipLabel,
    props = _objectWithoutProperties(_ref, _excluded);
  var Wrapper = useMemo(function () {
    return tooltipLabel ? function (_ref2) {
      var children = _ref2.children;
      return __jsx(Tooltip, {
        label: tooltipLabel,
        hasArrow: true,
        placement: 'left',
        offset: [0, 16],
        padding: 4
      }, children);
    } : Fragment;
  }, [tooltipLabel]);
  return __jsx(Wrapper, null, __jsx(FormLabel, _extends({
    fontSize: "sm",
    mb: 1,
    fontWeight: 'normal',
    cursor: 'pointer'
  }, props)));
}