/**
 * Heavily inspired by
 * @see https://github.com/wangweiwei/video-metadata-thumbnails/blob/master/src/video/index.ts
 */
import { canvasToBlob } from '../canvas.js';
export function getVideoThumbnail(url, options = { scale: 1, start: 1, quality: 1 }) {
    const videoElement = document.createElement('video');
    videoElement.preload = 'auto';
    videoElement.playsInline = true;
    videoElement.muted = true;
    videoElement.volume = 0;
    videoElement.crossOrigin = 'anonymous';
    videoElement.src = url;
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    return new Promise((resolve, reject) => {
        const { start, scale, quality } = options;
        let canPlay = false;
        const canplayHandler = () => {
            if (!canPlay) {
                videoElement.currentTime = start;
            }
            canPlay = true;
        };
        const timeupdateHandler = () => {
            const { videoWidth, videoHeight } = videoElement;
            const scaledVideoWidth = videoWidth * (scale || 1);
            const scaledVideoHeight = videoHeight * (scale || 1);
            canvas.width = scaledVideoWidth;
            canvas.height = scaledVideoHeight;
            canvasContext.drawImage(videoElement, 0, 0, scaledVideoWidth, scaledVideoHeight);
            canvasToBlob(canvas, 'image/jpeg', quality)
                .then((blob) => {
                endedHandler();
                resolve(blob);
            })
                .catch((error) => {
                endedHandler();
                reject(error);
            });
        };
        const endedHandler = () => {
            videoElement?.removeEventListener('ended', endedHandler, false);
            videoElement?.removeEventListener('canplay', canplayHandler, false);
            videoElement?.removeEventListener('timeupdate', timeupdateHandler, false);
            videoElement?.removeEventListener('error', errorHandler, false);
            // This is how we make sure the video is fully unloaded.
            // Thanks https://stackoverflow.com/a/28060352/707320.
            videoElement?.pause();
            videoElement?.removeAttribute('src');
            videoElement?.load();
            videoElement?.remove();
        };
        const errorHandler = () => {
            const { error } = videoElement;
            if (error) {
                reject(error);
            }
            else {
                reject(new Error('__NAME__ unknown error'));
            }
            endedHandler();
        };
        // for ios safari. the video won't load unless the user hits play or autoplay is true.
        // adding muted for good measure, although it will likely not be an issue.
        videoElement.muted = true;
        videoElement.autoplay = true;
        videoElement.addEventListener('canplay', canplayHandler, false);
        videoElement.addEventListener('timeupdate', timeupdateHandler, false);
        videoElement.addEventListener('ended', endedHandler, false);
        videoElement.addEventListener('error', errorHandler, false);
    });
}
