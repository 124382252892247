import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["status", "children"],
  _excluded2 = ["slug", "onChange", "status", "slugHint", "setInputValue", "inputValue"];
var __jsx = React.createElement;
import { FormControl, FormHelperText } from '@chakra-ui/react';
import { Input } from '@playful/design_system';
import { slugify } from '@playful/utils';
import React from 'react';
export function SlugHint(_ref) {
  var status = _ref.status,
    children = _ref.children,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(FormHelperText, _extends({
    color: status === 'error' ? 'red' : 'gray.500'
  }, props), children);
}
export function SlugEditor(_ref2) {
  var slug = _ref2.slug,
    onChange = _ref2.onChange,
    status = _ref2.status,
    slugHint = _ref2.slugHint,
    setInputValue = _ref2.setInputValue,
    inputValue = _ref2.inputValue,
    inputProps = _objectWithoutProperties(_ref2, _excluded2);
  function handleChange(e) {
    var value = e.currentTarget.value;
    setInputValue(value);
    onChange === null || onChange === void 0 || onChange(slugify(value));
  }
  return __jsx(FormControl, {
    isInvalid: status === 'error'
  }, __jsx(Input, _extends({
    onChange: handleChange,
    spellCheck: false,
    variant: "filled",
    value: inputValue,
    onKeyDown: function onKeyDown(e) {
      return e.code === 'Enter' && e.currentTarget.blur();
    }
  }, inputProps)), __jsx(SlugHint, {
    status: status
  }, slugHint));
}