import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip as ChTooltip } from '@chakra-ui/tooltip';
import { Text } from '../Text';
/**
 * Tooltip component that renders a tooltip on hover. See [Tooltip](https://v1.chakra-ui.com/docs/components/overlay/tooltip) for the complete documentation.
 */
export function Tooltip(props) {
    return _jsx(ChTooltip, { ...props });
}
export function TitledTooltip({ title, body, children, TitleProps, BodyProps, ...props }) {
    if (!title || !body)
        return _jsx(Tooltip, { ...props, children: children });
    return (_jsx(Tooltip, { px: 4, py: 3, boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.08), 0 3px 25px 0 rgba(0, 0, 0, 0.15)', borderRadius: '8px', borderColor: 'gray.700', bg: 'gray.700', label: _jsxs(_Fragment, { children: [_jsx(Text, { color: 'accent.500', size: 'xs', fontWeight: 'bold', ...TitleProps, children: title }), _jsx(Text, { size: 'xs', mt: 1, color: 'white', ...BodyProps, children: body })] }), ...props, children: children }));
}
