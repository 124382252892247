import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useCallback, useEffect, useRef } from 'react';
/*
Handles drag and drop for a given HTMLElement reference
Adds and removes the appropriate event listeners

Recommended: Wrap any handlers passed into this hook with the useCallback (or useRef) hook to avoid reapplying the event listeners on every render.
*/
export var useDragAndDrop = function useDragAndDrop(props) {
  var onDrag = props.onDrag,
    allowedTypes = props.allowedTypes,
    onDragIn = props.onDragIn,
    onDragOut = props.onDragOut,
    onDrop = props.onDrop,
    disabled = props.disabled,
    _props$dragEffect = props.dragEffect,
    dragEffect = _props$dragEffect === void 0 ? 'none' : _props$dragEffect;
  var elRef = useRef(null);
  var memoizedDragIn = useCallback(function handleDragIn(event) {
    var _event$dataTransfer;
    event.preventDefault();
    event.stopPropagation();
    if (disabled) return;
    var _ref = ((_event$dataTransfer = event.dataTransfer) === null || _event$dataTransfer === void 0 ? void 0 : _event$dataTransfer.items) || [],
      _ref2 = _slicedToArray(_ref, 1),
      fileToDrop = _ref2[0];
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = dragEffect;
    }
    onDragIn === null || onDragIn === void 0 || onDragIn(event, {
      canDrop: allowedTypes ? allowedTypes === null || allowedTypes === void 0 ? void 0 : allowedTypes.includes(fileToDrop.type) : true
    });
  }, [onDragIn, dragEffect, allowedTypes, disabled]);
  var memoizedDragOut = useCallback(function handleDragOut(event) {
    event.preventDefault();
    event.stopPropagation();
    if (disabled) return;
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = dragEffect;
    }
    onDragOut === null || onDragOut === void 0 || onDragOut(event);
  }, [onDragOut, dragEffect, disabled]);
  var memoizedDrag = useCallback(function handleDrag(event) {
    event.preventDefault();
    event.stopPropagation();
    if (disabled) return;
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = dragEffect;
    }
    onDrag === null || onDrag === void 0 || onDrag(event);
  }, [onDrag, dragEffect, disabled]);
  var memoizedDrop = useCallback(function handleDrop(event) {
    var _event$dataTransfer2;
    event.preventDefault();
    event.stopPropagation();
    if (disabled) return;
    var _ref3 = ((_event$dataTransfer2 = event.dataTransfer) === null || _event$dataTransfer2 === void 0 ? void 0 : _event$dataTransfer2.files) || [],
      _ref4 = _slicedToArray(_ref3, 1),
      droppedFile = _ref4[0];
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = dragEffect;
    }
    onDrop === null || onDrop === void 0 || onDrop(event, {
      canDrop: allowedTypes ? allowedTypes === null || allowedTypes === void 0 ? void 0 : allowedTypes.includes(droppedFile.type) : true
    });
  }, [onDrop, dragEffect, allowedTypes, disabled]);
  var memoOnDragOver = useCallback(function onDragOver(event) {
    event.preventDefault();
    if (disabled) return;
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = dragEffect;
    }
  }, [dragEffect, disabled]);
  useEffect(function () {
    var element = elRef.current;
    if (element) {
      element.addEventListener('dragenter', memoizedDragIn);
      element.addEventListener('dragleave', memoizedDragOut);
      element.addEventListener('dragover', memoizedDrag);
      element.addEventListener('drop', memoizedDrop);
      element.addEventListener('dragover', memoOnDragOver);
    }
    return function () {
      if (element) {
        element.removeEventListener('dragenter', memoizedDragIn);
        element.removeEventListener('dragleave', memoizedDragOut);
        element.removeEventListener('dragover', memoizedDrag);
        element.removeEventListener('drop', memoizedDrop);
        element.removeEventListener('dragover', memoOnDragOver);
      }
    };
  }, [memoizedDragIn, memoizedDrag, memoizedDragOut, memoizedDrop, memoOnDragOver]);
  return [elRef];
};