var __jsx = React.createElement;
import { VStack } from '@chakra-ui/react';
import { getConfig } from '@playful/config';
import { Box, CloudDownloadIcon, IconButton, Link, Text, Tooltip, useIsSmallScreen } from '@playful/design_system';
import React, { useState } from 'react';
import { useProjectSettingsContext } from './ProjectSettingsContext';
export function CurrentUrlDisplay(_ref) {
  var onEdit = _ref.onEdit;
  var _useProjectSettingsCo = useProjectSettingsContext(),
    _useProjectSettingsCo2 = _useProjectSettingsCo.projectInfo,
    id = _useProjectSettingsCo2.id,
    title = _useProjectSettingsCo2.title,
    pageMetadata = _useProjectSettingsCo2.pageMetadata,
    published = _useProjectSettingsCo2.published,
    publicUrl = _useProjectSettingsCo.publicUrl;
  var _useState = useState(false),
    isQrDownbloadShown = _useState[0],
    setIsQrDownloadShown = _useState[1];
  var isSmallScreen = useIsSmallScreen();
  var qrCodeUrl = "".concat(getConfig().apiRoot, "/projects/").concat(id, "/qrcode?published=").concat(published);
  var qrCodeDownloadUrl = "".concat(getConfig().apiRoot, "/projects/").concat(id, "/qrcode?size=500&published=").concat(published);
  return __jsx(Box, {
    display: 'flex',
    alignItems: 'center',
    mb: 4,
    mt: 1,
    gap: 3
  }, __jsx(Box, {
    width: '96px',
    height: '96px',
    minWidth: '96px',
    minHeight: '96px',
    padding: 2,
    border: '1px solid var(--play-colors-gray-100)',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
    role: 'group',
    position: 'relative',
    overflow: 'hidden'
  }, __jsx("img", {
    src: qrCodeUrl
  }), __jsx(Box, {
    _groupHover: {
      opacity: 1
    },
    position: 'absolute',
    opacity: isQrDownbloadShown ? 1 : 0,
    transition: 'opacity 0.2s ease-in-out',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    color: 'gray.700',
    onClick: function onClick() {
      if (isSmallScreen) {
        setIsQrDownloadShown(true);
      }
    }
  }, (!isSmallScreen || isQrDownbloadShown) && __jsx(Tooltip, {
    label: 'download image of QR code'
  }, __jsx(IconButton, {
    as: 'a',
    pointerEvents: isQrDownbloadShown ? 'auto' : 'none',
    _groupHover: {
      pointerEvents: 'auto'
    },
    backgroundColor: 'gray.50',
    download: "".concat((pageMetadata === null || pageMetadata === void 0 ? void 0 : pageMetadata.title) || title, "-qr-code.png"),
    href: qrCodeDownloadUrl,
    "aria-label": 'download qr code',
    icon: __jsx(CloudDownloadIcon, null),
    onClick: function onClick(e) {
      setIsQrDownloadShown(false);
      e.stopPropagation();
    }
  })))), __jsx(VStack, {
    w: "100%",
    alignItems: "flex-start",
    spacing: 2
  }, __jsx(Link, {
    color: 'gray.700',
    href: publicUrl,
    isExternal: true,
    textDecoration: "underline",
    _hover: {
      textDecoration: 'none'
    }
  }, __jsx(Text, {
    wordBreak: 'break-word'
  }, publicUrl)), __jsx(Link, {
    onClick: onEdit,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px'
  }, "edit publish settings")));
}