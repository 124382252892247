import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
var __jsx = React.createElement;
import { Text } from '@playful/design_system';
import React from 'react';
export function SettingTitle(_ref) {
  var children = _ref.children,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Text, _extends({
    as: 'h2',
    fontSize: 'lg',
    fontWeight: 'medium',
    color: 'gray.700',
    py: 2
  }, props), children);
}